body {
  margin: 0;
  padding: 0;
  height:"100vh" !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  cursor: pointer;
 font-family: sans-serif !important;
  padding: 17px !important;
  box-sizing: border-box;
  outline:none;
}
.dropdown-toggle:focus{
  outline:2px solid #f50e9e;  
}
#dropdown-font{
  background: none !important;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border: 1px solid #ccc;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: scroll;
}
.css-au2vfx-MuiStepper-root , .MuiStepper-root{
  width:"98%" !important;
  padding-left:"14px" !important;
}

.dropdown-list div {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-list div:hover {
  background-color: #ddd;
}

kendo-pdf-document{
  font-size: 12px !important;
}
.pdf-sec{
  display: none !important;
}
#pdf-sec{
  display: none !important;
}

kendo-pdf-document  .pdf-sec{
  display: block !important;
}
kendo-pdf-document  #pdf-sec{
  display: grid !important;
}
.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
  display: flex !important;
}
.css-ka7ti6-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment:hover{
  display: flex !important;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
  display: none !important;
}
.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator:hover{
  display: none !important;
}
.jobautocomplete .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator{
  display: block !important;
}
.jobautocomplete .css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator:hover{
  display: block !important;
}
.gm-style-iw-chr{
  display: none !important;
}
